body {
  font-family: $font_0, $font_1;
  color: $color1;
  background-color: #000;
  position: relative;
  font-size: 16px;
  overflow-x: hidden;
}
h1 {
  line-height: 1.4em;
  font-size: 42px;
  color: hsla(0, 0%, 100%, 0.85);
}
h2 {
  line-height: 28px;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: $primary_color;
}
h3 {
  line-height: 28px;
  font-size: 24px;
  font-weight: 700;
  margin-top: 20px;
  color: #586082;
  letter-spacing: 1px;
}
h4 {
  line-height: 28px;
  font-size: 20px;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 0;
  color: $white;
  letter-spacing: 1px;
}
h5 {
  line-height: 28px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 12px;
  margin-bottom: 0;
  letter-spacing: 0.1em;
  color: $white;
}
h6 {
  line-height: 28px;
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-top: 25px;
  color: $theme-gray;
  margin-bottom: 25px;
}
p {
  line-height: 23px;
  font-size: 16px;
  color: $theme-gray;
  letter-spacing: 0.05rem;
}
a {
  &:hover {
    color: $secondary_color;
    text-decoration: none;
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.theme-bg {
  background: $bg_gradiant;
}
.text-black {
  color: $black !important;
}
button:focus {
  outline: none;
}
.f-bold {
  font-weight: 700;
}
.f-color {
  color: $theme-gray;
}
.ml-12 {
  margin-left: 12px;
}
.section-title {
  margin-bottom: 50px;
}
.theme-text {
  color: $secondary_color !important;
}
.margin-50 {
  margin-top: 50px;
}
.btn-theme {
  background-color: $primary_color;
  background: $bg_gradiant;
  color: $white !important;
  font-size: 14px;
  border-radius: 5px;
  padding: 10px 30px;
  font-weight: 600;
  text-transform: capitalize;
  display: inline-block;
  border: 0;
  letter-spacing: 1px;
  &:hover {
    background: $primary_color;
    background-color: $primary_color;
  }
}
.btn-theme-primary {
  background-color: $secondary_color;
  color: $white !important;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 50px;
  padding: 10px 30px;
  font-weight: 600;
  transition: all 0.3s ease-in;
  border: 1px solid $secondary_color;
  &:hover {
    background-color: transparent;
    color: $secondary_color !important;
    border: 1px solid $secondary_color;
    transition: all 0.3s ease-in-out;
  }
}
.d-flex-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.display-flex {
  display: flex;
  align-items: center;
}
#instantclick-bar {
  background: $secondary_color;
}

.section {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;
}
.margin-top-100 {
  margin-top: 110px;
}

/* Tap on top */
.tap-top {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 150px;
  right: 30px;
  z-index: 99;
  color: $white;
  text-align: center;
  background: $bg_gradiant;
  border-radius: 100%;
  font-size: 22px;
  border: 1px solid $white;
  cursor: pointer;
  display: none;
  i {
    vertical-align: middle;
  }
}

/* Inner Page */
.inner-page {
  .request-page {
    min-height: inherit;
    .main {
      padding: 0;
    }
    .auth-card {
      width: 400px;
    }
  }
}
.nav-item.dropdown {
  .dropdown-menu {
    min-width: 200px;
    background: $white;
    display: none;
    left: 15px;
    right: 0;
    margin: 0;
    border: 0;
    opacity: 0;
    padding: 20px;
    visibility: hidden;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 6px 12px $black_17;
    transition: all 0.6s ease-in;
  }
  &:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
li.nav-item.dropdown {
  &:last-child .dropdown-menu {
    right: 0;
    left: auto;
  }
  &:first-child .dropdown-menu {
    left: 15px;
  }
}
.dropdown-menu .nav-item .nav-link {
  padding: 5px;
  font-size: 14px;
  letter-spacing: 0.3px;
  transition: all 0.3s ease;
  text-transform: capitalize;
  &:hover {
    color: $primary_color !important;
    transition: all 0.3s ease;
  }
}

.sub-title {
  font-size: 20px;
  margin-bottom: 0;
}
.swiper-slide img {
  width: 100%;
  margin-top: -25px;
  margin-bottom: -25px;
}
.swiper-container-horizontal > .swiper-pagination-bullets {
  position: relative;
  margin-top: 55px;
}

.z-index-1000 {
  z-index: 1000 !important;
}

.tags .badge-theme {
  padding: 5px 7px;
  font-size: 12px;
  background-color: $primary_color;
  margin-right: 2px;
  margin-bottom: 5px;
  color: $white;
  font-weight: 400;
  transition: 0.3s ease;
  &:hover {
    background-color: darken($secondary_color, 10%);
  }
}
.page-item.active .page-link {
  background-color: $primary_color;
  border-color: $primary_color;
}
.form-check-input.is-invalid ~ .form-check-label {
  color: $theme-gray;
  font-size: 15px;
}
.margin-bottom > div + div + div + div .review-box {
  margin-top: 30px;
}
.mb-50 {
  margin-bottom: 50px;
}
.request-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Faq Page */
.faq {
  .faq-row {
    align-items: center;
    justify-content: center;
    margin-bottom: -30px;
    margin-top: -17px;
  }
  .card {
    border-radius: 7px !important;
    border: 1px solid #eeeef7;
    .card-body {
      font-size: 16px;
      padding: 20px 30px;
      color: $theme-gray;
      border-top: 1px solid rgba(88, 96, 130, 0.2);
      letter-spacing: 0.05rem;
    }
    h5 {
      margin-top: 0;
    }
  }
  .accordion {
    .card {
      border-bottom: 1px solid #eeeef7 !important;
    }
    button {
      background-color: transparent;
      border: 0;
      width: 100%;
      text-align: left;
      color: $theme-gray;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.04rem;
      padding: 0;
      &[aria-expanded='true'] {
        color: $primary_color;
        i {
          &:before {
            content: '\f106';
          }
        }
      }
      i {
        float: right;
        transition: all 0.3s ease;
        padding-top: 5px;
      }
    }
  }
  .card-header {
    border: 0;
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    padding: 15px 30px;
    &[aria-expanded='true'] {
      button {
        color: $primary_color;
      }
    }
    &.collapsed {
      i {
        transition: all 0.3s ease;
      }
    }
  }
}
.dropdown-btn {
  padding: 6px 8px 6px 40px;
  text-decoration: none;
  font-size: 16px;
  color: $white;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
  outline: none;
}
.title span {
  font-weight: 700;
}

.theme-nav {
  padding-top: 0px;
  padding-bottom: 0px;
  transition: all 0.3s ease;
  .navbar {
    background-color: transparent;
  }
  .nav-link {
    display: block;
    padding: 25px 1rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
  }
  .nav-item {
    font-size: 14px;
    padding: 20px 5px;
    color: $white;
    text-transform: uppercase;
    font-weight: 600;
  }
  .dropdown-menu {
    .nav-item {
      font-size: 13px;
      padding: 0;
    }
    .nav-link {
      color: $primary_color !important;
    }
  }
  &.darkHeader {
    padding-top: 0;
    padding-bottom: 0;
    .nav-item {
      padding: 0px 5px;
      transition: all 0.3s ease;
    }
    .dropdown-menu .nav-item {
      padding: 0;
      .nav-link {
        &.active {
          color: #18e7d3;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
  .navbar-nav .active > .nav-link {
    color: $secondary_color;
  }
  .navbar-toggler-icon i {
    color: $white;
    display: flex;
    align-items: center;
    height: 100%;
  }
}
.navbar-toggler {
  padding-right: 0;

  &:focus {
    outline: none !important;
    box-shadow: none;
  }

  .navbar-toggler-icon {
    width: auto;
  }
}

.navbar-nav {
  .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.6);
  }
  > li.dropdown {
    position: relative;
  }
  a {
    &.nav-link {
      &.active {
        pointer-events: none;
        color: $white;
      }
    }
  }
}
.btn-menu {
  background-color: $secondary_color;
  color: $white !important;
  border-radius: 50px;
  padding: 10px 54px;
  width: 150px;
  text-align: center;
}
.darkHeader {
  background: $bg_gradiant;
  box-shadow: 1px 1px 35px 0 $color_mine_shaft_40_approx;
  transition: all 0.3s ease;
}
.slide-bg {
  background: $bg_gradiant;
  height: 100vh;
  padding-bottom: 0;
  padding-top: 80px;
  .container {
    height: 100%;
    .row {
      height: 100%;
      .home-right {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }
}

.hidden {
  visibility: hidden;
}

.removed {
  display: none;
}

.go-modal__title {
  font-size: 16px;
}
