@import 'assets/css/common/variable';

.feature {
  .row {
    align-items: center;
  }
}
.feature-box {
  padding: 60px 0;
}
.feature-mobile {
  margin-bottom: -33px;
  text-align: center;
}
.screenshots {
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 90%;
    max-width: 90%;
    margin-left: 5%;
  }
}
.feature-timeline {
  text-align: right;
  &:after {
    content: '';
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    height: 100%;
    width: 1px;
    right: 0;
    background-size: cover;
    border-radius: 12px;
    top: 0;
  }
}
.timeline {
  &:last-child {
    p {
      margin-bottom: 0;
    }
  }
  h4 {
    color: $white !important;
    margin-top: 0;
  }
  p {
    color: $white;
    margin-bottom: 55px;
    line-height: 1.8em;
    margin-left: 12px;
    font-weight: 300;
    margin-right: 15px;
  }
  &:before {
    content: '';
    background: $secondary_color;
    position: relative;
    height: 12px;
    width: 12px;
    right: -21px;
    background-size: cover;
    top: 15px;
    border-radius: 50%;
    z-index: 2;
    float: right;
    padding: 0;
    border: 3px solid rgba(255, 255, 255, 0.9);
  }
}
.timeline-right {
  h4 {
    color: $white !important;
    margin-top: 0;
  }
  p {
    color: $white;
    margin-bottom: 55px;
    margin-left: 12px;
    line-height: 1.8em;
    font-weight: 300;
  }
  &:last-child {
    p {
      margin-bottom: 0;
    }
  }
  &:before {
    content: '';
    background: $secondary_color;
    position: relative;
    height: 12px;
    width: 12px;
    left: -21px;
    top: 15px;
    border-radius: 50%;
    z-index: 2;
    float: left;
    padding: 0;
    border: 3px solid rgba(255, 255, 255, 0.9);
  }
}
.feature-timeline-right:after {
  content: '';
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  height: 100%;
  width: 1px;
  left: 0;
  background-size: cover;
  border-radius: 12px;
  top: 0px;
}
