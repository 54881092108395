@import 'assets/css/common/variable';

.button {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 0.3em;
  padding: 0.8em 2em;
}

.button--primary {
  background: $button_primary;
  color: $button_primary_text;

  &:hover {
    background: $button_primary_hover;
    color: $button_primary_text;
  }
}

.button--secondary {
  color: $button_secondary_text;
  text-decoration: none;
  border: 3px solid $button_secondary;

  &:hover {
    color: $button_primary_text;
    border-color: transparent;
    background: $button_secondary_hover;
    cursor: pointer;
  }
}

.button--small {
  font-weight: initial;
  letter-spacing: initial;
  padding: 0.3em 1em;
}

.button--medium {
  font-weight: initial;
  letter-spacing: initial;
  padding: 0.5em 1.3em;
}

.button--block {
  width: 100%;
}

.loading {
  pointer-events: none;
}

.spinner {
  height: 22px;
}
