@import 'assets/css/common/variable';

.animated-circle i {
  background: $bg_gradiant;
  left: 0;
  box-shadow: 0 15px 30px 0 $black_11;
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  opacity: 0.3;
  z-index: 0 !important;
  transform: scale(1.3);
  -webkit-animation: ripple1 3s linear infinite;
  animation: ripple1 3s linear infinite;
  &:nth-child(2) {
    -webkit-animation: ripple2 3s linear infinite;
    animation: ripple2 3s linear infinite;
  }
  &:nth-child(3) {
    -webkit-animation: ripple3 3s linear infinite;
    animation: ripple3 3s linear infinite;
  }
}

@keyframes ripple1 {
  0% {
    transform: scale(5.5);
    opacity: 0.3;
  }
  100% {
    transform: scale(8.5);
    opacity: 0;
  }
}
@-webkit-keyframes ripple1 {
  0% {
    -ms-transform: scale(5.5); /* IE 9 */
    -webkit-transform: scale(5.5); /* Safari */
    transform: scale(5.5);
    opacity: 0.3;
  }
  100% {
    -ms-transform: scale(8.5); /* IE 9 */
    -webkit-transform: scale(8.5); /* Safari */
    transform: scale(8.5);
    opacity: 0;
  }
}
@keyframes ripple2 {
  0% {
    -ms-transform: scale(3.5); /* IE 9 */
    -webkit-transform: scale(3.5); /* Safari */
    transform: scale(3.5);
  }
  100% {
    -ms-transform: scale(5.5); /* IE 9 */
    -webkit-transform: scale(5.5); /* Safari */
    transform: scale(5.5);
  }
}
@-webkit-keyframes ripple2 {
  0% {
    -ms-transform: scale(3.5); /* IE 9 */
    -webkit-transform: scale(3.5); /* Safari */
    transform: scale(3.5);
  }
  100% {
    -ms-transform: scale(5.5); /* IE 9 */
    -webkit-transform: scale(5.5); /* Safari */
    transform: scale(5.5);
  }
}
@keyframes ripple3 {
  0% {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari */
    transform: scale(1.5);
  }
  100% {
    -ms-transform: scale(3.5); /* IE 9 */
    -webkit-transform: scale(3.5); /* Safari */
    transform: scale(3.5);
  }
}
@-webkit-keyframes ripple3 {
  0% {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari */
    transform: scale(1.5);
  }
  100% {
    -ms-transform: scale(3.5); /* IE 9 */
    -webkit-transform: scale(3.5); /* Safari */
    transform: scale(3.5);
  }
}

.animated-circle--top i {
  top: 0;
}
