@import './common/variable';

#terms {
    p {
        color: #FFF !important;
        font-weight: 100;
    }
    
    h2 {
        color: #FFF !important;
        font-weight: 400;
        margin-top: 50px;
    }
    
    h3 {
        font-size: 1.7rem;
        color: #FFF !important;
        font-weight: 400;
        margin-top: 50px;
    }

    h4 {
        font-size: 1.7rem;
        color: #FFF !important;
        font-weight: 200;
        margin-top: 50px;
    }
}

#wrapper {
    width: 70%;
    margin: auto;
    padding-bottom: 100px;
}

.bg {
    min-height: 100vh;
    background: $bg_gradiant;
    padding-bottom: 0;
    padding-top: 80px;
}

ul.terms-list {
    color:#FFF;
    font-weight: 100;
    font-size: 1.0rem;
    list-style-type: circle !important;
    margin-left: 30px;
    margin-bottom: 20px;
}
