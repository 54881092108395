@import 'assets/css/common/variable';

.bg {
  background-color: $white;
}

img.store {
  width: 150px;
}

.slide-bg {
  background: $bg_gradiant;
  height: 100vh;
  padding-bottom: 0;
  padding-top: 80px;
  .container {
    height: 100%;
    .row {
      height: 100%;
    }
  }
}
.slide-text {
  margin-top: -20px;
  h4 {
    font-size: 18px;
    color: #cfcaff;
    font-weight: 400;
  }
}
.slid-btn {
  margin-top: 70px;
  a:first-child {
    margin-right: 15px;
  }
}
