@import './common/variable';

.process-box{
    box-shadow: 0px 1px 5px 3px #f9f9f9;
    background: $white;
    border: 1px solid rgba(89, 96, 130, 0.1);
    padding: 30px 25px 25px;
    &:hover{
        .bg-icon {
            transform: scale(2.5) rotate(5deg);
            transition: all 0.3s ease;
        }
    }
    .bg-icon {
        opacity: 0.05;
        transform: scale(2.5) rotate(15deg);
        position: absolute;
        left: 80px;
        bottom: 60px;
        transition: all 0.3s ease;
    }
    p{
        margin-bottom: 0;
    }
    img{
        height: 60px;
    }
    h3{
        margin-top: 30px;
    }
}