// ~~~~~~~~~~~~~~~~~~~~
// User
// ~~~~~~~~~~~~~~~~~~~~
//colors shade
$white: #ffffff;
$color_bon_jour_approx: #e0e0e0;
$color_gray_nurse_approx: #e8e8e8;
$color_alto_approx: #dddddd;
$color_gallery_approx: #eee;
$color_cararra_approx: rgba(236, 236, 236, 1);
$color_zircon_approx: #f5fcff;
$light-gray: #f9f9f9;
$color_wild_sand_approx: #f5f4f4;
$color_bon_jour_60_approx: rgba(224, 224, 224, 0.6);
$color_cararra_60_approx: rgba(236, 236, 236, 0.6);
$color_mountain_mist_approx: #999999;
$color_silver_chalice_approx: #aaaaaa;
$color_cararra_11_approx: rgba(236, 236, 236, 0.11);
$color_mine_shaft_approx: #333;
$color_mine_shaft_40_approx: rgba(51, 51, 51, 0.4);
$black_4: rgba(0, 0, 0, 0.04);
$black_11: rgba(0, 0, 0, 0.11);
$black_10: rgba(0, 0, 0, 0.1);
$black_17: rgba(0, 0, 0, 0.17);
$black: #000;
$theme-gray: #586082;
$color_saffron_approx: #eeb829;

// ~~~~~~~~~~~~~~~~~~~~
// Developer
// ~~~~~~~~~~~~~~~~~~~~
$dev_primary_color: #633fac;
$dev_secondary_color: #110f2c;
$dev_bg: #312f3e;
$dev_bg_hover: #3a339831;
$dev_bg_gradiant: linear-gradient(to right, #2d1544 0%, #110f2c 100%);
$dev_color: #bbb;
$dev_color2: #777;
$dev_header: #444057;
$dev_card: #423f53;
$dev_modal: #211f2b;

// ~~~~~~~~~~~~~~~~~~~~
// Common
// ~~~~~~~~~~~~~~~~~~~~

//fonts
$font_0: Lato;
$font_1: sans-serif;

//urls
$url_0: url(assets/images/screen1.png);
$url_1: url(assets/images/testimonial.jpg);
$url_2: url(assets/images/bg-pattern.png);

//Color settings
$primary_color: #393398;
$secondary_color: #9647db;

$bg_gradiant: linear-gradient(to right, $primary_color 0%, $secondary_color 100%);
$color_bay_of_many_45_approx: rgba($primary_color, 0.45);

//Buttons
$button_primary: #5f57ea;
$button_primary_hover: #6862e4;
$button_primary_text: #fff;
$button_secondary: #3a3594;
$button_secondary_hover: #6862e4;
$button_secondary_text: #fff;

// TODO: Exported from developer app, make sure to sync it across apps
$color1: #bbb;
$color2: #777;
$color3: #f0f0f0;
$color4: #586082;
$color5: #131313;
