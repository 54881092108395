@import 'assets/css/common/variable';

.about-box {
  border-bottom: 1px solid $color_alto_approx;
  padding-bottom: 58px;
}
.about-border {
  border-right: 1px solid $color_alto_approx;
}
.chat-box {
  text-align: center;
  img {
    transition: all 0.5s;
  }
  &:hover {
    img {
      transform: scale(1.1);
      transition: all 0.5s;
    }
  }
}
.chat-slide {
  text-align: center;
  padding-top: 58px;
  h3 {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.about-chat .col-md-3:last-child .chat-box {
  border-right: none;
}

.full-banner {
  width: 85%;
}
