@import './common/variable';

.download{
    position: relative;
    text-align: center;
    margin: 0 auto;
    .manual-down {
        color: #a9a9a9;
    }
    h3{
        margin-top: 30px;
        margin-bottom: 0;
        font-size: 28px;
    }
    h4{
        color: #444;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .icon{
        height: 150px;
    }
}
.download-bg {
    background: $bg_gradiant;
    padding: 40px 0;
}
.footer-logo {

    padding: 20px 0;
}
.top-bottom-padng{
    margin-bottom: 50px;
    margin-top: 55px;
}
.download-text {
    h3 {
        margin-top: 0;
        color: $white;
        font-weight: 500;
        font-size: 22px;
        margin-bottom: 0;
    }
}
.download-img {
    ul {
        li {
            margin-right: 7px;
            display: inline-block;
            margin-top: 0px;
        }
    }
    img{
        width: 120px;
    }
}

.download-nav {
    background-color: $primary_color;
}
.downoad-img ul {
    display: inline-block;
}