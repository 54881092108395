@import 'assets/css/common/variable';

.home-right {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mobile-slid {
  text-align: center;
}
.player-message {
  position: absolute;
  top: 18%;
  left: -40%;
  #player-message-image {
    width: 344px;
    height: 100px;
    animation: movebounce 2.1s linear infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    font-size: 1.3rem;
  }
}
@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(8px);
  }
  100% {
    transform: translateX(0px);
  }
}
.my-message {
  position: absolute;
  top: 63%;
  right: -30%;
  #my-message-image {
    width: 254px;
    height: 92px;
    animation: moveleftbounce 2.1s linear infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.3rem;
  }
}
.profile-1 {
  position: absolute;
  bottom: -3%;
  right: -45%;
  &:before {
    animation: animeffctslide 2.5s linear infinite;
    box-shadow: 0 0 0 80px $color_gray_nurse_approx;
    position: absolute;
    content: '';
    width: 110px;
    height: 110px;
    top: 26%;
    left: 26%;
    border-radius: 50%;
    z-index: 0;
  }
}
.emoji {
  position: absolute;
  left: -20%;
  bottom: 10%;
  img {
    animation: moveleftbounce 2.1s linear infinite;
  }
}
.profile-2 {
  position: absolute;
  left: -40%;
  top: -5%;
  &:before {
    animation: animeffctslide1 2.5s linear infinite;
    box-shadow: 0 0 0 80px rgba(232, 232, 232, 0.1);
    position: absolute;
    content: '';
    width: 110px;
    height: 110px;
    top: 26%;
    left: 26%;
    border-radius: 50%;
    z-index: 0;
  }
}
@-webkit-keyframes animeffctslide {
  0% {
    box-shadow: 0 0 0 8px $color_bon_jour_approx;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 15px $color_bon_jour_60_approx;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}
@keyframes animeffctslide {
  0% {
    box-shadow: 0 0 0 8px $color_bon_jour_approx;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 15px $color_bon_jour_60_approx;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}

@-webkit-keyframes animeffctslide1 {
  0% {
    box-shadow: 0 0 0 8px rgba(232, 232, 232, 0.1);
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 15px $color_bon_jour_60_approx;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}
@keyframes animeffctslide1 {
  0% {
    box-shadow: 0 0 0 8px rgba(232, 232, 232, 0.1);
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 15px $color_bon_jour_60_approx;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}
