@import 'assets/css/common/variable';

.logo {
  width: 180px;
}

.theme-nav {
  padding-top: 0px;
  padding-bottom: 0px;
  transition: all 0.3s ease;
  .navbar {
    background-color: transparent;
  }
  .nav-link {
    display: block;
    padding: 25px 1rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
  }
  .nav-item {
    font-size: 14px;
    padding: 20px 5px;
    color: $white;
    text-transform: uppercase;
    font-weight: 600;
  }
  .dropdown-menu {
    .nav-item {
      font-size: 13px;
      padding: 0;
    }
    .nav-link {
      color: $primary_color !important;
    }
  }
  &.darkHeader {
    padding-top: 0;
    padding-bottom: 0;
    .nav-item {
      padding: 0px 5px;
      transition: all 0.3s ease;
    }
    .dropdown-menu .nav-item {
      padding: 0;
      .nav-link {
        &.active {
          color: #18e7d3;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
  .navbar-nav .active > .nav-link {
    color: $secondary_color;
  }
  .navbar-toggler-icon i {
    color: $white;
    display: flex;
    align-items: center;
    height: 100%;
  }
}
.navbar-toggler {
  padding-right: 0;

  &:focus {
    outline: none !important;
    box-shadow: none;
  }

  .navbar-toggler-icon {
    width: auto;
  }
}

.navbar-nav {
  .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.6);
  }
  > li.dropdown {
    position: relative;
  }
  a {
    &.nav-link {
      &.active {
        color: $white;
      }
    }
  }
}

.flexbox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hide-on-desktop {
  display: none;
}

.navbar-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-right__lang-icon {
  width: 25px;
}

.navbar-right__lang-icon:hover {
  cursor: pointer;
}

.navbar-right__sign-in {
  margin-left: 1em;
}

.nav-link {
  cursor: pointer;
}

@media (max-width: 62em) {
  .hide-on-desktop {
    display: block;
  }

  .hide-on-mobile {
    display: none;
  }

  .flexbox {
    display: block;
  }

  .sign-in {
    display: none;
  }
}
