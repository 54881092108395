@media (min-width: 1500px) and (max-width: 1366px) {
  .home-right {
    margin-top: -30px;
  }
}
@media (min-width: 1367px) and (max-width: 1500px) {
  .pc-bg {
    width: 400px;
    height: 240px;
    background-size: contain;
  }

  .img-scroll {
    left: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 57px);
    top: 11px;
  }

  .phone-wrapper {
    transform: scale(0.8);
  }
}

@media (max-width: 1366px) {
  .timeline-right h4,
  .timeline h4 {
    font-size: 18px;
  }
  .price-box {
    h2 {
      font-size: 50px;
    }
  }

  .mobile-slid {
    margin-top: -10px;
  }
  .contact {
    .auth-form {
      padding-top: 2px;
    }
  }

  .slide-text {
    margin-top: -15px;
  }
  .plan-slider,
  .plan-slider-rtl {
    .owl-nav {
      .owl-prev {
        left: -15px;
      }
      .owl-next {
        right: -15px;
      }
    }
  }
  .faq .faq-row {
    margin-bottom: -28px;
    margin-top: -15px;
  }

  .breadcrumb-bg {
    padding: 20px 0;
  }
  .inner-page section {
    // padding-top: 70px;
    // padding-bottom: 70px;
  }
  .slid-btn {
    > a {
      img {
        width: 120px;
      }
    }
  }
  .footer-logo {
    padding: 0;
  }
  .cooming-soon {
    .left {
      z-index: 9;
    }
  }
  .price-plan li {
    margin-bottom: 25px;
  }
  .feature-mobile {
    img {
      width: 80%;
      margin: 20px auto;
    }
  }
  .about {
    .full-banner {
      width: 70%;
    }
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 22px;
    margin-top: 10px;
  }
  .chat-slide {
    padding-top: 30px;
    h3 {
      margin-bottom: 0;
    }
  }
  .section-title {
    margin-bottom: 40px;
  }
  .section {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .phone-wrapper {
    transform: scale(0.6);
  }
  .slide-text {
    height: auto;
  }
  .login-form {
    min-height: 600px;
  }
  .profile-msg {
    width: 190px;
    left: 60px;
    top: 45%;
  }
  .awesome {
    top: 45%;
    width: 160px;
    right: 80px;
  }
  .blog-text h3 {
    margin-bottom: 10px;
  }
  .slide-text {
    h1 {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 20px;
    }
  }
  .about-box {
    padding-bottom: 30px;
  }
  .chat-box {
    img {
      height: 30px;
    }
  }
}

@media (max-width: 1199px) {
  .process-box {
    padding: 20px 15px 15px;
    img {
      height: 55px;
    }
  }
  .swiper-slide {
    img {
      margin-top: -15px;
      margin-bottom: -15px;
    }
  }
  .slide-bg {
    .container {
      .row {
        .home-right {
          justify-content: flex-end;
        }
      }
    }
  }

  .timeline p {
    margin-right: 10px;
    margin-left: 0;
  }
  .email-box {
    padding: 20px 10px;
  }
  .faq {
    .faq-row {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
  .display-flex {
    justify-content: center;
    .download-text {
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }
  .testimonil-box {
    .owl-dots {
      margin-left: 240px;
    }
  }
  .slid-btn {
    img {
      width: 120px;
    }
  }
  .contact-box {
    li {
      padding-left: 70px;
    }
  }
  h1 {
    font-size: 38px;
  }
  .load i {
    top: 0;
  }
  .team-slider .owl-nav {
    margin-top: 82px;
  }
  .contact-text h3 {
    font-size: 16px;
  }
  .section {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .home-right {
    width: 400px;
    position: relative;
    float: right;
  }
  .feature-mobile {
    text-align: center !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .mobile-slid {
    text-align: right;
  }
  .blog-text {
    h6 {
      margin-top: 15px;
    }
    h5 {
      line-height: 20px;
    }
  }
  .marg-20 {
    margin: 10px auto;
  }
  .blog-block {
    padding: 5px;
  }
  h1 {
    font-size: 38px;
    line-height: 56px;
  }
  .footer-logo {
    padding: 0;
  }
  .profile-msg {
    top: 44%;
    left: unset;
    right: 120px;
  }
  .awesome {
    right: -30px;
    top: 190px;
  }
  .slider-mobile {
    width: 210px;
  }
  .inner-page {
    // padding-top: 100px;
  }
  .navbar-nav .nav-item .nav-link {
    padding-left: 3px;
    padding-right: 3px;
    font-size: 13px;
  }
  .blog-pagin a.page-link {
    font-size: 14px;
  }
  .contact-right img {
    width: 85%;
  }
  .blog-list > div + div + div .blog-item {
    margin-top: 40px;
  }
  .blog-cat-detail ul li i {
    margin-right: 5px;
  }
  .pages-space > div + div + div {
    margin-top: 50px;
  }
  .animated-circle:before {
    bottom: 72px;
  }
  .faq {
    .accordion {
      button {
        font-size: 15px;
      }
    }
    .card-header {
      padding: 12px 26px;
    }
    .card .card-body {
      font-size: 15px;
      padding: 12px 26px;
    }
  }
  .contact-text {
    h3 {
      font-size: 15px;
      margin-top: 0;
    }
    p {
      font-size: 15px;
      margin-bottom: 0;
    }
  }
  .contact-box li + li {
    margin-top: 15px;
  }
  .contact .auth-form {
    padding-right: 50px;
  }
}

@media (max-width: 1024px) {
  .contact-box {
    li {
      & + li {
        margin-top: 25px;
      }
    }
  }
  .login-form {
    min-height: auto;
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .animated-circle:before {
    bottom: 72px;
  }
  .slide-text {
    h1 {
      font-size: 28px;
    }
    h4 {
      font-size: 16px;
    }
  }
  .section-title {
    img {
      transform: scale(0.7);
    }
    h2 {
      font-size: 28px;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 991px) {
  .work {
    .row {
      .col-lg-4 {
        & + .col-lg-4 {
          margin-top: 20px;
        }
      }
    }
  }
  .slide-bg {
    padding-top: 60px;
    height: auto;
    .container {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .home-right {
      height: auto;
    }
  }

  .faq-row {
    .text-center {
      display: none;
    }
  }
  .testimonil-box .owl-dots {
    margin-left: 35%;
  }
  .error-main {
    position: relative;
    .error-font {
      font-size: 240px;
    }
  }
  .section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .section-title {
    margin-bottom: 25px;
  }
  .about .full-banner {
    width: 100%;
  }
  .feature-mobile {
    display: none;
  }
  .home-right {
    width: 340px;
  }
  .feature-timeline {
    margin-right: 50px;
  }
  .feature-timeline-right {
    margin-left: 50px;
  }
  .download-bg {
    .display-flex {
      justify-content: center;
      .footer-logo {
        padding: 0;
      }
    }
  }
  .about-box {
    padding-bottom: 0;
    .chat-box {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $color_alto_approx;
    }
  }
  .about-border {
    & + div {
      & + div {
        .chat-box {
          padding-bottom: 0;
          border-bottom: 0;
        }
      }
    }
    &:nth-child(2) {
      border: 0;
    }
  }

  .theme-nav {
    padding: 10px !important;

    .navbar-brand {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
  .testi-profile {
    .media-body {
      padding-right: 0;
    }
  }
  h1 {
    font-size: 35px;
  }
  .slider-mobile {
    display: none;
  }
  .footer-logo {
    border-right: none;
  }
  .download-text {
    text-align: center;
    h3 {
      font-size: 18px;
    }
  }
  .email-box {
    text-align: center;
    padding-bottom: 0;
  }
  .slide-text a img {
    margin-right: 4px;
    margin-bottom: 10px;
  }
  .news-carousel .owl-nav {
    margin-top: 30px;
  }
  .download-img ul {
    text-align: center;
  }
  .plan-slider .owl-nav {
    margin-top: 10px;
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        padding: 8px;
      }
    }
  }
  #mymenu {
    background-color: $primary_color;
    border-radius: 5px;
    margin-top: 15px;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid rgba(255, 255, 255, 0.15);
  }
  .darkHeader {
    box-shadow: 1px 1px 12px 1px rgba(0, 0, 0, 0.13);
  }
  #navbarSupportedContent {
    background: $white;
    .nav-item {
      padding: 0;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  .hover-text {
    padding: 0 15px;
  }
  .breadcrumb-bg {
    padding: 10px 0;
  }
  .inner-page {
    // padding-top: 70px;
    section {
      // padding-bottom: 50px;
      // padding-top: 50px;
    }
    .blog-pagination {
      margin-top: 50px;
    }
  }
  .leave-coment {
    margin-top: 0;
  }
  .md-height {
    height: 100%;
  }
  .mt-70 {
    text-align: center;
  }

  .question {
    padding: 10px 10px 10px 40px;
  }
  .content {
    padding: 0;
  }
  .nav-item.dropdown .dropdown-menu {
    box-shadow: none;
    padding: 0 0 0 15px;
  }
  .dropdown-menu .nav-item {
    margin-left: 0;
  }
  #mymenu .dropdown-menu {
    display: none;
    transition: 0.5s ease;
    &.show {
      display: block;
      transition: 0.5s ease;
      opacity: 1;
      visibility: visible;
      margin-bottom: 15px;
    }
    .nav-item {
      margin-left: 0;
    }
  }
  .navbar-light .navbar-nav .nav-link {
    color: $theme-gray;
  }
  .darkHeader.navbar-light .navbar-nav .nav-link.active {
    color: $black !important;
  }
  .theme-nav .nav-item {
    padding: 0 !important;
  }
  .social-icon li {
    margin-left: 10px;
    margin-right: 10px;
  }
  .animated-circle:before {
    bottom: 95px;
  }
  ol.breadcrumb.bg-transparent.mb-0 {
    padding: 10px 0;
  }
  .blog-list > div + div .blog-item {
    margin-top: 40px;
  }
  .blog-text h5 {
    line-height: 1.6;
  }
  .blog-carousel .owl-dots {
    position: relative;
    top: 10px;
  }
  .blog-sec .blog-list > div + div .blog-item {
    margin-top: 40px;
  }
  .blog-description p {
    font-size: 15px;
  }
  .reply-comment p {
    font-size: 14px;
  }
  #clockdiv > div {
    padding: 6px;
  }
  .download {
    .dwn-logo {
      width: 80%;
    }
    .app1 {
      width: 45%;
    }
  }
  .md-fgrup-margin {
    margin-bottom: 20px;
  }
  .subscribe {
    text-align: center;
    border: 0;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .timeline {
    h4 {
      margin-right: 8px;
    }
  }
  .timeline-right {
    h4 {
      margin-left: 8px;
    }
  }
  .news-text,
  .blog-hover {
    text-align: center;
  }
  .slid-btn {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .animated-circle {
    margin-left: auto;
    margin-right: auto;
  }
  .swiper-slide {
    img {
      margin-top: -5px;
      margin-bottom: -5px;
    }
  }
  .slide-bg {
    .container {
      margin-top: 80px;
      .row {
        .home-right {
          justify-content: center;
          margin-top: -70px;
        }
      }
    }
  }
  .news-text {
    p {
      padding-top: 5px;
    }
  }
  .news-text {
    h4 {
      line-height: 1.5;
      margin-top: 10px;
    }
  }
  .team-hover {
    text-align: center;
  }
  .home-right {
    float: none;
  }
  .hover-text {
    margin-top: 10px;
    padding: 0;
  }
  .contact .auth-form {
    padding: 0 15px;
    margin-bottom: 50px;
  }
  .contact {
    text-align: center;
  }
  .testimonil-box .owl-dots {
    width: 100%;
  }
  .auth-page {
    .mobile-slid {
      margin: 0;
    }
  }
  .clock-box {
    text-align: center;
  }
  .request-right {
    order: -1;
    margin-bottom: 30px;
  }
  .request-left {
    .text-left {
      text-align: center !important;
    }
  }
  .contact-box {
    li {
      padding: 0;
    }
  }
  .contact-circle {
    position: relative;
    margin: 0 auto 10px;
  }
  h4 {
    font-size: 18px;
  }
  .timeline-right,
  .timeline {
    p {
      margin-bottom: 19px !important;
    }
  }
  .testimonil-box {
    .owl-dots {
      text-align: center;
      margin-left: 0;
      margin-top: -30px !important;
    }
  }
  .mobile-slid {
    img {
      margin-bottom: 0;
    }
  }
  .home-right {
    margin: 0 auto;
  }
  .mobile-slid {
    text-align: center;
  }
  .slider-mobile {
    display: none;
  }
  .slide-text {
    text-align: center;
  }
  .news-carousel .owl-nav {
    margin-top: 0;
  }
  .chat-box h3 {
    font-size: 18px;
  }
  .profile-2 {
    &:before {
      display: none;
    }
  }
  .profile-1 {
    &:before {
      display: none;
    }
  }
  ol {
    &.breadcrumb {
      &.bg-transparent {
        &.mb-0 {
          padding: 10px 0 0;
        }
      }
    }
  }
  .testimonial h3 {
    font-size: 18px;
  }
  h2 {
    font-size: 28px;
  }
  .subscribe {
    border-right: none;
    text-align: center;
  }
  .testi-profile {
    text-align: center;
    margin-bottom: 50px;
  }
  .testi-profile .media-body {
    padding: 30px;
  }
  .testi-profile .media {
    display: block;
  }
  .animated-circle:before {
    bottom: 95px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .animated-circle {
    position: relative;
    margin-bottom: 80px;
  }
  .testimonial-slider .owl-item img {
    margin: 0 auto;
  }
  .load i {
    width: 500px;
    height: 500px;
    right: -250px;
  }
  .slider-mobile {
    width: 192px;
    display: block;
  }
  .profile-msg {
    left: -46px;
  }
  .awesome {
    right: 25px;
  }
  .chat-box {
    border-right: none;
  }
  .feature-timeline-right {
    &:after {
      top: 0px;
    }
  }
  .feature-box {
    padding: 0 !important;
  }
  .testimonial-slider {
    .media-body {
      text-align: center;
    }
    .owl-item img {
      display: block;
      width: 105px;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 30px;
    }
  }
  .team-profile {
    text-align: center;
  }
  .team-close-btn {
    top: 0;
    left: 0;
    position: relative;
    margin: 10px auto;
  }
  .footer-logo {
    border-right: none;
  }

  .subscribe {
    border-right: none;
    text-align: center;
  }
  .theme-breadcrumb {
    .breadcrumb {
      justify-content: center;
      align-items: center;
      padding: 10px;
      padding-bottom: 0;
    }
    li {
      line-height: 1.5;
      a {
        line-height: 1.5;
      }
    }
  }
  .col-text-center {
    text-align: center;
  }
  .d-align-center {
    justify-content: center;
  }
  .blog-cat-detail {
    .marg-15 {
      margin: 10px auto;
    }
    ul li a {
      font-size: 14px;
    }
  }
  .blog-text {
    h6 {
      margin-top: 15px;
    }
    h3 {
      margin-top: 5px;
    }
  }
  .subscribe {
    padding-bottom: 10px;
  }
  .email-box {
    padding: 0;
  }
  .social-bg {
    padding: 15px 0;
  }
  .sm-center {
    text-align: center;
    a:first-child {
      margin-right: 0;
    }
  }
  .flt-right {
    text-align: center;
  }
  #clockdiv {
    margin-bottom: 50px;
    font-size: 19px;
  }
  .demo-mob {
    margin-top: 30px;
  }
  .load i {
    width: 400px;
    height: 400px;
    right: -200px;
    top: 17%;
  }
  .testi-profile .media-body {
    padding-right: 0;
  }
  .testi-profile .media {
    display: block;
  }
  .margin-bottom > div + div .review-box {
    margin-top: 30px;
  }
  .display-flex {
    justify-content: center;
  }
  .sign-in-space {
    margin-top: 10px;
  }
  .testi-profile .media-body {
    padding: 0;
  }
  .chat-box {
    margin-bottom: 30px;
    h3 {
      margin-top: 0;
    }
  }
}

@media (max-width: 600px) {
  .timeline {
    p {
      margin-right: 0;
      margin-left: 20px;
    }
    h4 {
      margin-left: 20px;
    }
  }
  .timeline-right {
    p {
      margin-right: 0;
    }
    h4 {
      margin-left: 0;
    }
  }
  .md-fgrup-margin {
    margin-bottom: 20px;
  }
  .list-sidebar {
    margin-top: 50px;
  }
  .sm-hidden {
    display: none;
  }
  .awesome {
    top: 47%;
    right: 0;
  }
  .profile-msg {
    left: -20px;
  }
  .chat-box {
    border-right: none;
  }
  .btn-theme-primary {
    font-size: 12px;
    padding: 10px;
  }
  .feature-timeline {
    text-align: left;
    &:after {
      left: 10px;
    }
  }
  .slider-mobile {
    display: block;
  }
  .feature-box {
    padding: 0;
  }
  .download-bg {
    text-align: center;
  }
  .testimonil-box {
    padding: 0;
    .media {
      display: block;
    }
  }
  .address {
    margin-bottom: 40px;
  }
  .call {
    margin-bottom: 0;
  }
  .email {
    margin-top: 40px;
  }
  .blog-text p {
    margin-top: 0;
    font-size: 14px;
  }
  .nav-item.dropdown .dropdown-menu {
    right: 0;
  }
  .timeline:before {
    left: -11px;
    float: left;
  }
  .load i {
    width: 400px;
    height: 400px;
    right: -200px;
    top: 0;
  }

  .dropdown .dropdown-toggle {
    margin-top: 3px;
  }
  .reply-comment .media img {
    width: 40px;
  }
  .margin-top-100 {
    &.review-padding {
      margin-top: 53px;
    }
  }
}

@media (max-width: 575px) {
  .slid-btn {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .slide-text {
    margin-top: 15px;
  }
  .process-box {
    img {
      height: 45px;
    }
    h3 {
      margin-top: 15px;
      margin-bottom: 2px;
    }
  }
  .timeline,
  .timeline-right {
    text-align: center;
    position: relative;
    p {
      margin-left: 0;
      margin-right: 0;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);
    }
    &:after {
      content: '';
      position: absolute;
      width: 50px;
      border-bottom: 1px dashed rgba(255, 255, 255, 0.41);
      top: -10px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    h4 {
      font-size: 16px;
    }
  }
  .feature-timeline {
    ul {
      .timeline {
        &:first-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
  .inner-page {
    .blog-pagination {
      margin-top: 40px;
    }
  }
  .download .icon {
    height: 100px;
  }
  .error-main {
    .error-font {
      font-size: 150px;
      letter-spacing: -11px;
    }
  }
  .main {
    padding: 0;
  }
  .auth-card {
    width: 280px;
    padding: 30px 15px 15px;
    min-width: 80%;
  }
  .auth-form {
    .social-btns {
      .btn {
        margin-bottom: 10px;
      }
    }
  }
  .auth-page {
    padding-top: 15px;
    padding-bottom: 15px;
    p {
      font-size: 14px;
    }
    h2 {
      font-size: 26px;
      margin-top: 5px;
      margin-bottom: 0px;
    }
    .line {
      width: 50px;
    }
    .page-logo {
      height: 17px;
    }
  }
  // .navbar-brand {
  //   img {
  //     height: 35px;
  //   }
  // }
  .inner-page {
    // padding-top: 60px;
    section {
      // padding-bottom: 40px;
      // padding-top: 40px;
    }
  }
  .blog-details {
    .news-text {
      .btn-theme {
        margin-bottom: 20px;
      }
    }
  }
  .news-text {
    .btn-theme {
      padding: 3px 14px;
      font-size: 13px;
    }
  }
  .slide-text h1 {
    font-size: 24px;
    line-height: 1.5;
  }
  .home-right {
    width: 290px;
  }
  .team-box {
    width: 70%;
    margin: 0 auto;
  }
  .about-box {
    border-bottom: 0;
  }
  .timeline {
    &:last-child {
      p {
        margin-bottom: 25px;
      }
    }
  }
  .chat-slide {
    padding-top: 0;
  }
  .feature-timeline,
  .feature-timeline-right {
    margin-left: 0;
    margin-right: 0;
    &:after {
      display: none;
    }
  }
  .timeline,
  .timeline-right {
    &:before {
      display: none;
    }
  }
  .about-border {
    & + div {
      & + div {
        .chat-box {
          padding-bottom: 20px;
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }
  .marg-20 {
    margin: 7px auto;
  }
  .mb-xs-4 {
    margin-bottom: 15px;
  }
  .load i {
    width: 300px;
    height: 300px;
    right: -150px;
    top: 0;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row !important;
  }
  .blog-list > div + div .blog-item {
    margin-top: 40px;
  }
  .breadcrumb-bg h2 {
    justify-content: center;
    span {
      margin-bottom: 10px;
      margin-top: 15px;
    }
  }
  .theme-breadcrumb {
    float: none;
    padding-top: 0;
    padding-bottom: 15px;
  }
  ol.breadcrumb.bg-transparent.mb-0 {
    padding: 0;
  }
}
