@import 'assets/css/common/variable';

.slide-bg {
  background: $dev_bg_gradiant;
  height: 100vh;
  padding-bottom: 0;
  padding-top: 80px;
  .container {
    height: 100%;
    .row {
      height: 100%;
    }
  }
}
.slide-text {
  margin-top: -20px;
  h4 {
    font-size: 18px;
    color: #cfcaff;
    font-weight: 400;
  }
}
