.not-found {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.error-main h4 {
  color: #586082;
  font-weight: 400;
  margin: 20px 0;
}
.error-font {
  font-size: 300px;
  line-height: 1;
  font-weight: 900;
  color: #5f57ea;
  letter-spacing: -25px;
}
.error-main .error-font span {
  color: #586082;
}

@media (max-width: 36em) {
  .error-font {
    font-size: 200px;
  }
}
